@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-calendars/styles/material.css';
@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-notifications/styles/material.css';
@import "../node_modules/@syncfusion/ej2-react-grids/styles/material.css";

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), url("./assets/Tipografias/Montserrat.ttf");
}

body {
  margin: 0;
  /* font-family: "Jost"; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Montserrat";
}

/* html,body,#root,.App{height: 100vh; overflow-y: scroll;} */

html,
body,
#root,
.App {
  height: 90vh !important;
  font-family: "Montserrat";
}

.swal2-container {
  z-index: 2000 !important;
}

.react-tel-input {
  margin-top: 16px;
}

.react-tel-input .special-label {
  color: rgba(0, 0, 0, 0.54);
}

*:focus {
  outline: none !important;
}

/* 
.App {
  height: 100vh !important;
  overflow-y: auto; 
} */

code {
  font-family: "Montserrat";
}

button {
  border-radius: 100px !important;
  box-shadow: none !important;
}

.confirmButtonSweet {
  font-family: "Montserrat";
  background-color: #42a5f5 !important;
  border-color: #FFFFFF;
  min-width: 270px !important;
  letter-spacing: 0.5px;
  width: 270px !important;
  height: 45px !important;
  border: 1px solid #FFFFFF !important;
  border-radius: 100px !important;
  padding: 0;
  color: #FFFFFF !important;
  font-size: 16px !important;
  font-weight: 600;
}

.confirmButtonSweetWarning {
  font-family: "Montserrat";
  background-color: #FFFFFF !important;
  border-color: #42a5f5 !important;
  min-width: 270px !important;
  letter-spacing: 0.5px;
  width: 270px !important;
  height: 45px !important;
  border: 1px solid #42a5f5 !important;
  border-radius: 100px !important;
  padding: 0;
  color: #42a5f5 !important;
  font-size: 16px !important;
  font-weight: 600;
}

.confirmButtonSweetCustom {
  font-family: "Montserrat";
  background-color: #fff !important;
  color: #000 !important;
  border-radius: 25px !important;
  min-width: 270px !important;
  font-weight: 600;
}

.confirmButtonSweetConfirm {
  font-family: "Montserrat";
  background-color: #42a5f5 !important;
  /* border-radius: 25px !important;  */
  border-color: #FFFFFF;
  min-width: 185px !important;
  letter-spacing: 0.5px;
  width: 185px !important;
  height: 45px !important;
  border: 1px solid #FFFFFF !important;
  border-radius: 100px !important;
  padding: 0;
  color: #FFFFFF !important;
  font-size: 16px !important;
  font-weight: 600;
}

.confirmButtonSweetCancel {
  font-family: "Montserrat";
  background-color: #FFFFFF !important;
  border-color: #42a5f5;
  min-width: 185px !important;
  letter-spacing: 0.5px;
  width: 185px !important;
  height: 45px !important;
  border: 1px solid #42a5f5 !important;
  border-radius: 100px !important;
  padding: 0;
  color: #42a5f5 !important;
  font-size: 16px !important;
  font-weight: 600;
}

.confirmButtonSweet:focus,
.confirmButtonSweetCustom:focus,
.confirmButtonSweetConfirm:focus,
.confirmButtonSweetCancel:focus {
  box-shadow: none !important;
}

.confirmButtonSweet:hover,
.confirmButtonSweetCustom:hover,
.confirmButtonSweetConfirm:hover,
.confirmButtonSweetCancel:hover {
  background-color: #0060A2 !important;
}

.confirmButtonSweetWarning:focus,
.confirmButtonSweetWarning:focus {
  box-shadow: none !important;
}

.confirmButtonSweetWarning:hover,
.confirmButtonSweetWarning:hover {
  background-color: #0060A2 !important;
}

.swal2-container {
  z-index: 2200 !important;
}

.react-tel-input .form-control {
  width: 100%;
  height: 45px;
  border-radius: 13px;
  font-size: 16px;
}

.swal2-popup {
  max-width: 600px;
  padding: 0 0 1.25em !important;
}

.swal2-icon-alertNoti {
  border: none;
  border-color: #FFFFFF !important;
  width: 90% !important;
  /* height: 30px !important; */
  /* width: 7em !important;
  height: 6em !important; */
  /* margin: .1em auto .1em !important; */
  margin: 2.5em auto 0.6em !important;
}

.swal2-icon .swal2-icon-content {
  max-width: 20%;
  justify-content: center;

}

.swal2-confirm {
  font-family: "Montserrat";
  background-color: #42a5f5 !important;
  border-color: #FFFFFF;
  min-width: 270px !important;
  letter-spacing: 0.5px;
  width: 270px !important;
  height: 45px !important;
  border: 1px solid #FFFFFF !important;
  border-radius: 100px !important;
  padding: 0;
  color: #FFFFFF !important;
  font-size: 16px !important;
  font-weight: 600;
}

.swal2-confirm:hover {
  background-color: #0060A2 !important;
}

.swal2-icon-content {
  /* width: 150px;
  height: 150px; */
}

.dstoast {
  padding: 8px !important;
}

.dstoast .swal2-title {
  font-size: 14px !important;
  font-weight: 500 !important;
}

.videoUbi {
  width: 80%;
  object-fit: cover;
  object-position: center center;
}

.swiper-button-next {
  color: red !important;
  padding: 30px 20px 30px 20px  !important;
  border-radius: 10px;
}

.swiper-button-next:hover{
  background-color: #dddddd !important;
  /* border: 2px solid #dddddd !important; */
}

.swiper-button-prev {
  color: red !important;
  padding: 30px 20px 30px 20px  !important;
  border-radius: 10px;
}

.swiper-button-prev:hover {
  background-color: #dddddd !important;
}

@media only screen and (max-width: 812px) {
  .swal2-popup {
    max-width: 280px;
  }

  .swal2-icon .swal2-icon-content {
    max-width: 100%;
    justify-content: center;
  }

  .swal2-icon-content-img {
    width: 50px !important;
    height: 50px !important;
  }

  .swal2-html-container {
    margin: 1em 0.6em .3em;
    font-size: 15px;
  }

  .confirmButtonSweet {
    background-color: #42a5f5 !important;
    /* border-radius: 25px !important;  */
    min-width: 240px !important;
    width: 240px !important;
    height: 38px !important;
    border: 1px solid #FFFFFF !important;
    border-radius: 100px !important;
    padding: 0;
    color: #FFFFFF !important;
    font-size: 14px !important;
    font-weight: 600;
    font-family: "Montserrat";
  }

  .confirmButtonSweetConfirm {
    background-color: #42a5f5 !important;
    /* border-radius: 25px !important;  */
    border-color: #FFFFFF;
    min-width: 185px !important;
    letter-spacing: 0.5px;
    width: 185px !important;
    height: 45px !important;
    border: 1px solid #FFFFFF !important;
    border-radius: 100px !important;
    padding: 0;
    color: #FFFFFF !important;
    font-size: 16px !important;
    font-weight: 600;
    font-family: "Montserrat";
  }

  .confirmButtonSweetCancel {
    background-color: #FFFFFF !important;
    border-color: #42a5f5;
    min-width: 185px !important;
    letter-spacing: 0.5px;
    width: 185px !important;
    height: 45px !important;
    border: 1px solid #42a5f5 !important;
    border-radius: 100px !important;
    padding: 0;
    color: #42a5f5 !important;
    font-size: 16px !important;
    font-weight: 600;
    font-family: "Montserrat";
  }

  .swal2-icon-alertNoti {
    border: none;
    border-color: #FFFFFF !important;
    width: 100% !important;
    /* height: 30px !important; */
    /* width: 7em !important;
    height: 6em !important; */
    margin: .1em auto .1em !important;
  }
}